import React from "react"
import { graphql } from "gatsby"
import SEO from "../components/seo"
import Layout from "../components/layout"

import "./blog-post.scss"

export default function BlogPostTemplate({ data }: { data: any }) {
  const { markdownRemark: post } = data
  return (
    <Layout>
      <SEO
        title="Super Productivity – To do list & time tracker for programmers & designers"
        description="Free to do list & time tracker for programmers & designers with build-in Jira & Github Integrations. Download for free or hack it for yourself."
      />

      <div className="container">
        <article>
          <h1>{post.frontmatter.title}</h1>
          <div
            className="blog-post-content"
            dangerouslySetInnerHTML={{ __html: post.html }}
          />
        </article>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query BlogPostByPath($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        title
      }
    }
  }
`
